<template>
  <div class="last section section--body">
    <div class="header">
      <h4>Останні результати</h4>

      <router-link to="/results" class="button button--outline">Переглянути всі</router-link>
    </div>
    <Loader v-if="isLoad" />

    <div class="cards" v-else>
      <div v-for="(item, index) in last.slice(-3)" :key="index">
        <Card :data="item" />
      </div>
    </div>
  </div>
</template>
<script>
  import Card from "../global/Card.vue";
  import data from "../../data/links.js";
  import axios from "axios";
  import Loader from "../global/Loader.vue";

  import { ref } from "vue";

  export default {
    components: {
      Card,
      Loader,
    },

    setup() {
      const isLoad = ref(true);
      const last = ref([]);

      const fetchLast = async () => {
        try {
          let get = await axios.get(data.dates),
            header = get.data.values[0],
            rows = get.data.values.slice(1),
            formated = [];

          for (const row of rows) {
            const formattedRow = {};

            header.forEach((e, i) => {
              formattedRow[e.toLowerCase().replace(/\s/g, "")] = row[i];
            });

            formated.push(formattedRow);
          }

          last.value = formated.filter((e) => e.погоджено != "");
        } catch (e) {
          console.log(e);
        }
      };

      fetchLast();

      setTimeout(() => {
        isLoad.value = false;
      }, 2000);

      return { isLoad, last };
    },
  };
</script>
<style lang="scss" scoped>
  .last {
    margin: 5rem 0 2rem 0;
    min-height: 400px;

    .header {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
    }

    .cards {
      margin-top: 2rem;
    }
  }
</style>
