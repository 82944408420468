<template>
  <Header />
  <router-view />
  <Footer />
</template>

<script>
  import Header from "@/components/global/Header.vue";
  import Footer from "./components/global/Footer.vue";
  export default {
    components: {
      Header,
      Footer,
    },
  };
</script>

<style lang="scss">
  #app {
    position: relative;
    font-family: "Nunito", sans-serif;
    font-weight: 300, 400, 500, 600, 800;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    display: block;
    width: 100%;
    // max-width: 1340px;
    min-height: 100vh;
    top: 0;
    left: 0;
    margin: 0 auto;
    box-sizing: border-box;
  }
</style>
