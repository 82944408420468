<template>
  <div class="card">
    <ImgComparisonSlider class="images">
      <!-- eslint-disable -->
      <img
        slot="before"
        :src="`https://drive.google.com/thumbnail?authuser=0&sz=w320&id=${data.фотопісля.split('=')[1]}`"
      />
      <img
        slot="after"
        :src="`https://drive.google.com/thumbnail?authuser=0&sz=w320&id=${data.фотодо.split('=')[1]}`"
      />
      <!-- eslint-enable -->
    </ImgComparisonSlider>
    <div class="labels">
      <p>До</p>
      <p>Після</p>
    </div>
    <div class="desription">
      <p class="name">{{ data.категорія }}</p>
      <p class="descr">{{ data.типробіт }}</p>
      <p class="descr">{{ data.позначкачасу.split(" ")[0] }}</p>
      <p class="descr">{{ data.адреса }}</p>
    </div>
  </div>
</template>
<script>
  import { ImgComparisonSlider } from "@img-comparison-slider/vue";

  export default {
    components: {
      ImgComparisonSlider,
    },
    props: ["data"],
  };
</script>
<style lang="scss" scoped>
  .card {
    display: grid;
    grid-gap: 8px;
    min-width: 320px;
    width: 100%;
    background: rgba($color: #fff, $alpha: 0.5);
    border-radius: 8px;

    .images {
      width: 100%;
      border-radius: 8px 8px 0 0;

      img {
        width: 100%;
        height: 320px;
        object-fit: cover;
      }
    }

    .labels {
      display: flex;
      justify-content: space-between;
      margin-top: -60px;
      z-index: 1;

      p {
        margin: 10px 10px 20px 10px;
        padding: 5px 10px 0 10px;
        border-radius: 999px;
        background: rgba($color: #fff, $alpha: 0.6);
        font-size: 0.8rem;
      }
    }

    .desription {
      padding: 12px;

      p {
        position: relative;
        display: block;
        width: 100%;
        padding: 0.5rem 0;
      }

      p:not(:last-child)::after {
        position: absolute;
        content: "";
        width: 100%;
        height: 1px;
        bottom: 0;
        left: 0;
        background: $light-grey;
      }

      .name {
        font-weight: 600;
        color: $acs;
        font-size: 1.2rem;
      }

      .descr {
        display: flex;
        color: #333;
        font-size: 0.8em;
      }
    }
  }
</style>
