<template>
  <div class="hero">
    <div class="holder">
      <p>Дбаємо про життя нашого міста</p>
    </div>
  </div>
</template>
<script>
  export default {};
</script>
<style lang="scss" scoped>
  .hero {
    position: relative;
    margin-top: -100px;

    display: grid;
    place-items: center;
    width: 100%;
    min-height: 680px;
    background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
    background-size: 400% 400%;
    animation: gradient 15s ease infinite;

    .holder {
      padding: 0 2rem;
      p {
        z-index: 1;
        color: #fff;
        font-weight: 500;
        font-size: 3em;
        text-align: center;
      }
    }
  }

  @keyframes gradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
</style>
