import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/results",
    name: "Results",
    meta: { title: "Результати роботи" },
    component: () => import("../views/Results.vue"),
  },
  {
    path: "/calendar",
    name: "Calendar",
    meta: { title: "Календар благоустрою" },
    component: () => import("../views/Calendar.vue"),
  },
  {
    path: "/contacts",
    name: "Contacts",
    meta: { title: "Повідомлення" },
    component: () => import("../views/Contacts.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    name: "Missing",
    meta: { title: "404" },
    component: () => import("../views/Missing.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

router.beforeEach((to, from, next) => {
  window.scrollTo(0, 0);
  document.title = to.meta.title ? to.meta.title + " | Благоустрій Бердичів" : "Благоустрій | Бердичів";
  next();
});

export default router;
