<template>
  <div class="gocalendar">
    <div class="section section--body">
      <div class="title">
        <p>Календар благоустрою</p>
        <router-link to="/calendar" class="button">Переглянути</router-link>
      </div>
      <svg
        id="b0c2ce5f-88b4-49a2-9766-ff0a73ff0798"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        width="971.55619"
        height="781.4172"
        viewBox="0 0 971.55619 781.4172"
      >
        <path
          d="M1078.5806,512.254H336.75838a7.20537,7.20537,0,0,1-7.1975-7.1975V66.4889a7.20537,7.20537,0,0,1,7.1975-7.1975H1078.5806a7.20536,7.20536,0,0,1,7.19749,7.1975V505.05649A7.20536,7.20536,0,0,1,1078.5806,512.254ZM336.75838,62.1704a4.32322,4.32322,0,0,0-4.3185,4.3185V505.05649a4.32322,4.32322,0,0,0,4.3185,4.3185H1078.5806a4.32321,4.32321,0,0,0,4.31849-4.3185V66.4889a4.32321,4.32321,0,0,0-4.31849-4.3185Z"
          transform="translate(-114.22191 -59.2914)"
          fill="#e6e6e6"
        />
        <path
          id="bbcc4262-a9b8-4c1e-bf70-e1245b5187b9"
          data-name="a332a05d-d774-4fd2-8531-ab97682a63e9"
          d="M636.16347,94.31766a5.707,5.707,0,0,0,0,11.4124H779.236a5.707,5.707,0,0,0,.20407-11.41212l-.01669-.00028q-.09366-.0016-.18738,0Z"
          transform="translate(-114.22191 -59.2914)"
          fill="#3f3d56"
        />
        <path
          d="M811.68137,92.72878a1.12288,1.12288,0,0,0-.00012,1.5879l.00012.00009,4.82024,4.82028H804.61537a1.12287,1.12287,0,0,0,0,2.24573h11.88624l-4.82024,4.82029a1.12285,1.12285,0,0,0,1.58793,1.588h0l6.73717-6.73714a1.12294,1.12294,0,0,0,0-1.588l-6.73717-6.73714a1.12278,1.12278,0,0,0-1.58787,0Z"
          transform="translate(-114.22191 -59.2914)"
          fill="#e6e6e6"
        />
        <path
          d="M603.6576,92.72878a1.12283,1.12283,0,0,1,.00009,1.5879l-.00009.00009-4.82023,4.82028h11.88624a1.12287,1.12287,0,1,1,.00266,2.24573h-11.8889l4.82023,4.82029a1.12286,1.12286,0,1,1-1.58795,1.588h0l-6.73714-6.73714a1.12294,1.12294,0,0,1,0-1.588l6.73714-6.73714a1.12284,1.12284,0,0,1,1.5879-.00006Z"
          transform="translate(-114.22191 -59.2914)"
          fill="#e6e6e6"
        />
        <path
          d="M329.13147,536.41184a32.89015,32.89015,0,1,1,32.89016-32.89015A32.89017,32.89017,0,0,1,329.13147,536.41184Z"
          transform="translate(-114.22191 -59.2914)"
          fill="#f4c259"
        />
        <path
          d="M342.10905,500.27734h-9.73313v-9.73326a3.24445,3.24445,0,1,0-6.4889,0v9.73326h-9.73326a3.2444,3.2444,0,1,0,0,6.4888H325.887v9.73325a3.24445,3.24445,0,0,0,6.4889,0v-9.73325h9.73313a3.2444,3.2444,0,1,0,0-6.4888Z"
          transform="translate(-114.22191 -59.2914)"
          fill="#fff"
        />
        <rect x="275.31813" y="93.44997" width="98.84565" height="67.17666" fill="#f1f1f1" />
        <rect x="409.67144" y="93.44997" width="98.84565" height="67.17666" fill="#f1f1f1" />
        <rect x="544.02476" y="93.44997" width="98.84565" height="67.17666" fill="#f1f1f1" />
        <rect x="678.37807" y="93.44997" width="98.84565" height="67.17666" fill="#f1f1f1" />
        <rect x="812.73138" y="93.44997" width="98.84565" height="67.17666" fill="#f1f1f1" />
        <rect x="275.68058" y="211.48895" width="98.84565" height="67.17666" fill="#f1f1f1" />
        <rect x="410.03389" y="211.48895" width="98.84565" height="67.17666" fill="#f1f1f1" />
        <rect x="544.38721" y="211.48895" width="98.84565" height="67.17666" fill="#f1f1f1" />
        <rect x="678.74052" y="211.48895" width="98.84565" height="67.17666" fill="#f1f1f1" />
        <rect x="813.09383" y="211.48895" width="98.84565" height="67.17666" fill="#f1f1f1" />
        <rect x="276.04303" y="329.52793" width="98.84565" height="67.17666" fill="#f1f1f1" />
        <rect x="410.39634" y="329.52793" width="98.84565" height="67.17666" fill="#f1f1f1" />
        <rect x="544.74966" y="329.52793" width="98.84565" height="67.17666" fill="#f1f1f1" />
        <rect x="679.10297" y="329.52793" width="98.84565" height="67.17666" fill="#f1f1f1" />
        <rect x="813.45628" y="329.52793" width="98.84565" height="67.17666" fill="#f1f1f1" />
        <circle cx="564.5402" cy="114.56263" r="12.47566" fill="#f4c259" />
        <circle cx="428.26756" cy="232.60161" r="12.47566" fill="#f4c259" />
        <circle cx="564.5402" cy="350.64059" r="12.47566" fill="#f4c259" />
        <circle cx="701.77251" cy="232.60161" r="12.47566" fill="#3f3d56" />
        <circle cx="835.16616" cy="232.60161" r="12.47566" fill="#3f3d56" />
        <circle cx="428.26756" cy="350.64059" r="12.47566" fill="#3f3d56" />
        <circle cx="101.16464" cy="446.62674" r="23.17166" fill="#ffb8b8" />
        <path
          d="M207.02049,643.02907q-.43776,0-.87939-.0387a10.38028,10.38028,0,0,1-9.31638-12.37427,9.71913,9.71913,0,0,1,.25473-.98672L162.88,589.18159a8.06132,8.06132,0,0,1-.56085-9.60846l.062-.07556,30.78363-30.35382a8.89853,8.89853,0,0,1,12.49527,12.67294q-.05792.05714-.11689.11316l-22.2955,21.1959,24.40808,39.17453a9.87852,9.87852,0,0,1,1.37668.16952,10.38,10.38,0,0,1-2.01193,20.55927Z"
          transform="translate(-114.22191 -59.2914)"
          fill="#ffb8b8"
        />
        <polygon
          points="139.328 769.118 150.895 769.118 156.397 724.505 139.326 724.506 139.328 769.118"
          fill="#ffb8b8"
        />
        <path
          d="M250.60008,824.63365l22.778-.00092h.00092A14.51569,14.51569,0,0,1,287.89512,839.148v.47235l-37.29437.00141Z"
          transform="translate(-114.22191 -59.2914)"
          fill="#2f2e41"
        />
        <polygon points="24.366 753.127 34.304 759.044 61.857 723.527 47.189 714.794 24.366 753.127" fill="#ffb8b8" />
        <path
          d="M137.98481,807.66411,157.55647,819.317l.00079.00049a14.51572,14.51572,0,0,1,5.04612,19.89844l-.00031.00048-.24133.40534L130.31726,820.5424Z"
          transform="translate(-114.22191 -59.2914)"
          fill="#2f2e41"
        />
        <path
          d="M267.84584,812.65245H254.89415a4.23771,4.23771,0,0,1-4.2215-3.79675L237.61546,686.11827a1.41542,1.41542,0,0,0-2.69717-.433L211.3629,737.844l-41.70359,68.00268a4.26565,4.26565,0,0,1-5.32891,1.66577l-16.66025-7.33a4.24676,4.24676,0,0,1-1.93479-6.06415l38.33707-64.19208,11.75766-54.0852c-3.69794-23.455,12.155-58.326,12.31577-58.67615l.07855-.17044,43.66751-17.06766.219.2681C269.33214,656.7086,279.826,717.527,272.07768,808.76262A4.27414,4.27414,0,0,1,267.84584,812.65245Z"
          transform="translate(-114.22191 -59.2914)"
          fill="#2f2e41"
        />
        <path
          d="M206.46516,622.70933l-.20337-.42749c-.0972-.20453-9.807-20.68365-17.292-42.57147a32.285,32.285,0,0,1,2.23859-25.87344A32.63729,32.63729,0,0,1,212.021,537.83265h0a32.677,32.677,0,0,1,37.92776,19.56977c5.93723,14.84707,4.045,30.69452,2.26429,43.15722l-.03547.2497-.22711.1087Z"
          transform="translate(-114.22191 -59.2914)"
          fill="#f4c259"
        />
        <path
          d="M243.48152,492.82893H206.68767V476.7906c8.07583-3.20837,15.97845-5.93692,20.75552,0a16.03847,16.03847,0,0,1,16.03836,16.03833Z"
          transform="translate(-114.22191 -59.2914)"
          fill="#2f2e41"
        />
        <path
          d="M204.65062,473.96031c-21.99607,0-28.1528,27.5712-28.1528,43.12552,0,8.67447,3.923,11.777,10.08755,12.82681l2.17709-11.611,5.099,12.11063c1.73163.00863,3.55078-.02488,5.43671-.05988l1.72885-3.55988,3.85526,3.496c15.44085.023,27.92062,2.27383,27.92062-13.2027C232.8029,501.53151,227.40355,473.96031,204.65062,473.96031Z"
          transform="translate(-114.22191 -59.2914)"
          fill="#2f2e41"
        />
        <path
          d="M302.42137,504.81776q.20983.38416.38766.79032a10.38032,10.38032,0,0,1-6.3927,14.10855,9.72348,9.72348,0,0,1-.98806.24951L276.32884,569.371a8.06133,8.06133,0,0,1-8.16324,5.09885l-.096-.01816L226.673,561.98955a8.89852,8.89852,0,0,1,5.1315-17.0412q.07744.02334.15451.04806l29.29034,9.40381,22.67642-40.20175a9.87567,9.87567,0,0,1-.51126-1.28943,10.38005,10.38005,0,0,1,19.0069-8.09131Z"
          transform="translate(-114.22191 -59.2914)"
          fill="#ffb8b8"
        />
        <path
          d="M307.22191,840.7086h-192a1,1,0,1,1,0-2h192a1,1,0,1,1,0,2Z"
          transform="translate(-114.22191 -59.2914)"
          fill="#ccc"
        />
      </svg>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .gocalendar {
    margin-top: -100px;

    .section {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
      align-items: center;
      grid-gap: 1em;
      border-radius: 8px;
      background: #fff;
      box-shadow: rgba(0, 0, 0, 0.2) 0px 18px 50px -10px;
    }

    .title {
      padding: 2rem;
      display: grid;
      place-items: center;

      p {
        font-weight: 600;
        font-size: 2rem;
        text-align: center;
      }
    }

    svg {
      width: 400px;
      height: 300px;
      margin-top: 2vh;
      justify-self: flex-end;

      @media only screen and (max-width: 1024px) {
        display: none;
      }
    }
  }
</style>
