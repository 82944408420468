<template>
  <div class="home">
    <Hero />
    <Calendar data-aos="fade-up" data-aos-delay="100" />
    <Last data-aos="fade-up" data-aos-delay="100" />
    <Message data-aos="fade-up" data-aos-delay="100" />

    <div class="section" data-aos="fade-up" data-aos-delay="100">
      <iframe
        id="preview"
        style="border: 0px; height: 500px; width: 100%"
        src="https://xn--r1a.website/s/brd24brd"
      ></iframe>
    </div>
  </div>
</template>

<script>
  import Calendar from "../components/home/Calendar.vue";
  import Hero from "../components/home/Hero.vue";
  import Last from "../components/home/Last.vue";
  import Message from "../components/home/Message.vue";
  export default {
    name: "Home",
    components: { Hero, Last, Calendar, Message },
  };
</script>

<style lang="scss" scoped>
  .section {
    margin-top: 3rem;

    iframe {
      border-radius: 8px;
      overflow-x: hidden;
    }
  }
</style>
