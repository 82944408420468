<template>
  <div class="header">
    <div class="mobile--menu" :class="{ active: isMenu }">
      <nav>
        <router-link to="/" @click="isMenu = false">Головна</router-link>
        <router-link to="/results" @click="isMenu = false">Результати роботи</router-link>
        <router-link to="/calendar" @click="isMenu = false">Календар</router-link>
        <router-link to="/contacts" @click="isMenu = false">Повідомлення</router-link>
      </nav>
    </div>
    <div class="bar section">
      <router-link to="/" class="logo">
        <p>Благоустрій</p>
      </router-link>
      <nav>
        <router-link to="/">Головна</router-link>
        <router-link to="/results">Результати роботи</router-link>
        <router-link to="/calendar">Календар</router-link>
        <router-link to="/contacts">Повідомлення</router-link>
      </nav>

      <div class="menu--btn" :class="{ active: isMenu }" @click="isMenu = !isMenu">
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
  </div>
</template>
<script>
  import { ref } from "vue";

  export default {
    setup() {
      const isMenu = ref(false);

      return { isMenu };
    },
  };
</script>
<style lang="scss" scoped>
  .header {
    position: relative;
    width: 100%;
    z-index: 11;
    background: rgba($color: #fff, $alpha: 0.1);

    .mobile--menu {
      position: absolute;
      width: 100%;
      top: 0;
      left: 0;
      right: 0;
      background: #fff;
      box-shadow: rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset, rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
        rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
      transform: translate3d(0, -200%, 0) skew(0, 20deg);
      transition: 0.3s ease-in;

      @media only screen and (min-width: 1024px) {
        display: none;
      }

      &.active {
        transform: translate3d(0, 0, 0) skew(0, 0deg);
      }

      nav {
        display: grid;
        place-items: center;
        margin: 100px 0 1rem 0;
        padding: 1rem;
        border-top: 1px solid $mid-grey;

        a {
          font-size: 1.5rem;
        }
      }
    }

    .bar {
      display: grid;
      grid-template-columns: 200px 1fr 200px;
      align-items: center;
      height: 100px;
      padding: 0 1rem;

      @media only screen and (max-width: 1024px) {
        grid-template-columns: 1fr 1fr;
      }

      .logo {
        color: $acs;
        font-weight: 800;
        font-size: 1.4em;
      }

      .button {
        @media only screen and (max-width: 1024px) {
          display: none;
        }
      }

      .menu--btn {
        display: flex;
        flex-direction: column;
        justify-content: center;
        justify-self: flex-end;
        align-items: center;
        width: 50px;
        height: 50px;
        cursor: pointer;

        @media only screen and (min-width: 1024px) {
          display: none;
        }

        &.active {
          span:nth-child(1) {
            transform: translateY(7.5px) rotate(45deg);
          }
          span:nth-child(2) {
            width: 0;
          }
          span:nth-child(3) {
            transform: translateY(-7.5px) rotate(-45deg);
          }
        }

        span {
          position: relative;
          display: block;
          width: 30px;
          height: 2px;
          background: #333;
          margin: 3px 0;
          background: $acs;
          border-radius: 2px;
          transition: 0.2s ease;
        }
      }

      nav {
        justify-self: center;

        @media only screen and (max-width: 1024px) {
          display: none;
        }

        a {
          color: #333;
          font-weight: 600;
          margin: 0 8px;

          &:hover::after {
            width: 100%;
          }

          &::after {
            position: absolute;
            content: "";
            width: 0%;
            height: 1px;
            bottom: 0;
            left: 0;
            background: #333;
            transition: 0.2s ease;
          }
        }
      }
    }
  }
</style>
